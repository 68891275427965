/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import { useMediaQuery } from 'react-responsive'

import Layout from '../components/layout/Layout'
import Section, { SectionScroller } from '../components/layout/Section'

import heroVideo from '../assets/media/herovideo.mp4'

// heroku test
const IndexPage = ({data}) => {
    const logo = <div className="logoContainer">
        <Img
            fixed={data.logo_light.childImageSharp.fixed}
            objectFit="cover"
            objectPosition="50% 50%"
            height={59}
            alt="BCM Solutions"
            className="logoDark"
        />
        <Img
            fixed={data.logo_dark.childImageSharp.fixed}
            objectFit="cover"
            objectPosition="50% 50%"
            height={59}
            alt="BCM Solutions"
            className="logoLight"
            aria-hidden="true"
        />
        </div>

    const isDesktop = useMediaQuery({query: '(min-width: 1024px)'})
    const isTablet = useMediaQuery({query: '(min-width: 767px) and (max-width: 1024px)'})
    const isMobile = useMediaQuery({query: '(max-width: 768px)'})
    const [deviceState, setDeviceState ] = useState('mobile')

    let device = deviceState
    switch(true){
        case isDesktop: 
            device = 'desktop'
        break
        case isTablet:
            device = 'tablet'
        break
        case isMobile: default:
            device = 'mobile'
        break
    }

    if (typeof window === 'undefined') {
        device = 'mobile'
    }

    useEffect(() => {
        setDeviceState(device)
    },[deviceState, device]);

    return (
        <Layout title={'Websites | Contemporary Web Design | BCM Solutions'} descr={'Ultra fast, custom website solutions and digital marketing consulting. Beautiful websites tailor made to your needs. Integrate with existing content management systems or use your own!'} logo={logo} device={deviceState}>
            <SectionScroller device={deviceState} headerScrollStart={550}>
                <Section 
                    forceVisible={true} 
                    name="❤" 
                    classes="isFH" 
                    theme="dark" 
                    video={{src: heroVideo, poster: data.videoImg.childImageSharp}} 
                    gradient={{from: '#325d7f', to: '#f2727f'}} 
                    device={deviceState}
                    layout="Hero"
                    content={{title: `We ❤ websites`, body: `We craft memorable user experiences through design and storytelling. The browser is our canvas`}}
                />
                <Section
                    classes="isLarge" 
                    name="Finely Tuned" 
                    theme="light" 
                    image={{src: data.section1bg.childImageSharp, color: '#fff'}} 
                    device={deviceState}
                    layout="TwoUp"
                    content={{subtitle: `Engineered for success`, title: `A website is a finely tuned machine`, body: `It is your website's job to connect you with your audience, across an array of devices. We understand this, which is why our websites are mobile-first, accessible, and 🗲 lightning fast.`, animation: 'responsive.json'}}
                />
                <Section
                    classes="isLarge" 
                    name="Get Leads" 
                    theme="dark" 
                    image={{src: data.section2bg.childImageSharp, color: '#2c5272'}}
                    device={deviceState}
                    layout="TwoUp"
                    content={{subtitle: `Crafted for conversions`, title: `Your website is a sales tool.`, body: `In order for it to serve it's purpose, it needs an audience. We guide you through the process of growing your online presence. We'll analyze your audience's behavior, and provide critical feedback on where improvements can be made`, animation: 'promotion.json'}}
                />
                <Section
                    classes="isLarge" 
                    name="Scale" 
                    theme="dark" 
                    image={{src: data.section3bg.childImageSharp, color: '#2c5272', size: 'full'}}
                    gradient={{from: '#f2727f', to: '#325d7f'}}
                    device={deviceState}
                    layout="TwoUp"
                    content={{subtitle: `Built for longevity`, title: `Scale as you grow`, body: `A website is an investment into the future of your company. It needs to grow with you. We manage your digital presence and guide you through the process of expanding your business online.`, animation: 'report.json'}}
                />
                <Section 
                    classes="isMedium" 
                    theme="light" 
                    name={false} 
                    image={{src: data.section4bg.childImageSharp, color: '#fff', fit: 'contain', align: 'right'}}
                    device={deviceState}
                    layout="CTA"
                    content={{subtitle: `Improve your digital presence`, title: `Ready to Grow Your Business?`, button: `Contact Us Now`}}
                />
                <Section 
                    classes="isFooter" 
                    theme="light" 
                    name="Contact Us" 
                    classTheme="light" 
                    device={deviceState}
                    layout="Footer"
                />
            </SectionScroller>
        </Layout>
    )
}

export default IndexPage

export const IndexQuery = graphql`
query IndexQuery {
    logo_light: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
            fixed(width: 189) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
   }
   logo_dark: file(relativePath: {eq: "logoDark.png"}) {
        childImageSharp {
            fixed(width: 189) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
   videoImg: file(relativePath: {eq: "videoBackground.jpg"}) {
        childImageSharp {
            desktop: fixed(width: 1920, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
            }
            tablet: fixed(width: 1024) {
                ...GatsbyImageSharpFixed_withWebp
            }
            mobile: fixed(width: 700) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    section1bg: file(relativePath: {eq: "whitetribg.jpg"}) {
        childImageSharp {
            desktop: fixed(width: 1920, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
            }
            tablet: fixed(width: 1024) {
                ...GatsbyImageSharpFixed_withWebp
            }
            mobile: fixed(width: 700) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    section1md: file(relativePath: {eq: "responsive.png"}) {
        childImageSharp {
            desktop: fluid(maxWidth: 576, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
            tablet: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
            mobile: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    section2bg: file(relativePath: {eq: "bluedotbg.jpg"}) {
        childImageSharp {
            desktop: fixed(width: 1920, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
            }
            tablet: fixed(width: 1024, quality: 70) {
                ...GatsbyImageSharpFixed_withWebp
            }
            mobile: fixed(height: 700, quality: 55) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    section2md: file(relativePath: {eq: "responsive.png"}) {
        childImageSharp {
            desktop: fluid(maxWidth: 576, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
            tablet: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
            mobile: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    section3bg: file(relativePath: {eq: "pinkblocks.jpg"}) {
        childImageSharp {
            desktop: fixed(width: 1920, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
            }
            tablet: fixed(width: 1024) {
                ...GatsbyImageSharpFixed_withWebp
            }
            mobile: fixed(width: 700) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    section3md: file(relativePath: {eq: "phone.png"}) {
        childImageSharp {
            desktop: fluid(maxWidth: 1024, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
            tablet: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
            mobile: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    section3md1: file(relativePath: {eq: "social_l1.png"}) {
        childImageSharp {
            desktop: fluid(maxWidth: 1024, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
            tablet: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
            mobile: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    section3md2: file(relativePath: {eq: "social_l2.png"}) {
        childImageSharp {
            desktop: fluid(maxWidth: 1024, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
            tablet: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
            mobile: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    section3md3: file(relativePath: {eq: "social_l3.png"}) {
        childImageSharp {
            desktop: fluid(maxWidth: 1024, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
            tablet: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
            mobile: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    section4bg: file(relativePath: {eq: "whitetriangle.jpg"}) {
        childImageSharp {
            desktop: fixed(width: 1920, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
            }
            tablet: fixed(width: 1024) {
                ...GatsbyImageSharpFixed_withWebp
            }
            mobile: fixed(width: 700) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    section4md: file(relativePath: {eq: "scale.png"}) {
        childImageSharp {
            desktop: fluid(maxWidth: 576, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
            tablet: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
            mobile: fluid(maxWidth: 576) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`